import React from 'react';
import { Authors } from '../../50-systems/ModuleStore/Authors';
import { internalModules } from '../../50-systems/ModuleStore/internalModules';
import { makeAttributeModule } from '../../50-systems/ModuleStore/makers/makeAttributeModule';

/**
 * Note: Color2AttributeModule, Color3AttributeModule and Weight2AttributeModule are here just to demonstrate how non standard attribute modules works.
 */
internalModules.declareModule(() =>
    makeAttributeModule({
        manifest: {
            flags: {
                isHidden: true,
            },
            name: 'Color2Attribute',
            title: { en: 'More colors', cs: 'Více barev' },
            description: {
                en: 'Allows user to pick more colors from the default system color picker.',
                cs: 'Umožňuje výběr více barev ze standardního systémového dialogového okna.',
            },

            categories: [
                'Experimental' /* TODO: Probbably experimental should be flag or some dev stage */,
                'Art',
                'Colors',
            ],
            icon: 'https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.62/modules/Color/icon.svg',

            contributors: [Authors.hejny],
        },
        standard: false,
        attribute: {
            type: 'string',
            name: 'color',
            defaultValue: '#000000',
            // TODO: Pattern:
        },
        inputRender(value: string, onChange: (value: string) => void) {
            return (
                <input
                    type="color"
                    {...{ value }}
                    onChange={(event) => onChange(event.target.value)}
                    style={{ height: '100%', margin: 0, width: 60 }}
                />
            );
        },
    }),
);
