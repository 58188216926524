import React from 'react';
import { IconColor } from '../../30-components/menu/IconColor';
import { Authors } from '../../50-systems/ModuleStore/Authors';
import { internalModules } from '../../50-systems/ModuleStore/internalModules';
import { makeAttributeModule } from '../../50-systems/ModuleStore/makers/makeAttributeModule';

// TODO: Generate these module automatically

const colors: Array<string> = ['#000000', '#333333', '#666666', '#999999', '#cccccc', '#eeeeee', '#ffffff'];

internalModules.declareModule(() =>
    makeAttributeModule({
        manifest: {
            name: 'GrayscaleColorAttribute',
            title: { en: 'Grayscale colors', cs: 'Nebarvy' },
            description: {
                // TODO: En: 'Use similar colors as those on H-edu',
                // TODO: Cs: 'Používejte na svých tabulích stejné barvy, jako jsou na H-edu',
            },
            icon: 'https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.62/modules/Grayscale/icon.svg' /* TODO: Generate automatically */,
            contributors: [Authors.hejny],
            categories: ['Colors'],
        },
        standard: false,
        attribute: {
            type: 'string',
            name: 'color',
        },
        inputRender(value: string, onChange: (value: string) => void) {
            return (
                <>
                    {colors.map((color) => (
                        <IconColor
                            key={color}
                            {...{ color }}
                            active={value === color}
                            onClick={() => onChange(color)}
                        />
                    ))}
                </>
            );
        },
    }),
);
