import styled from 'styled-components';
import { Color } from '../../40-utils/color/Color';
import { ISkin } from '../../50-systems/StyleSystem/ISkin';

export const ModalStyle = styled.div<{ skin: ISkin }>`
    .custom-modal {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 500px;
        max-width: calc(100vw - 120px);
        max-height: calc(100vh - 50px);
        display: block;
        background-color: ${({ skin }) => Color.from(skin.colors.white).opaque.toString()};
        z-index: 10000;
        border-radius: ${({ skin }) => skin.borderRadius}px;
    }
    .custom-modal.custom-modal-wide {
        width: 1200px;
    }
    .custom-modal.custom-modal-fullsize {
        height: calc(100vh - 50px);
    }
    .custom-modal .custom-modal-content {
        width: 100%;
        max-height: calc(100vh - 50px);

        overflow-x: hidden;
        overflow-y: hidden
            /* Note: Scrolling is supressed on whole modal and if you want to have a scrollable modal use <ModalScroll> component */;

        padding: 15px 20px;
        border-radius: ${({ skin }) => skin.borderRadius}px;

        /* Note: Dirty hack how to get rid of annoying white line on top edge of modals */
        margin-top: -1px;
    }
    .custom-modal .custom-modal-header {
        max-width: 100vw;
        background-color: ${({ skin }) => Color.from(skin.colors.primary).opaque.toString()};
        padding: 15px 20px 12px 20px;
        margin: -15px -20px 15px -20px;
        color: ${({ skin }) => Color.from(skin.colors.white).opaque.toString()};
        font-size: 1.3rem;
        /*
        TODO: @roseckyj I have commented this line. Is it OK?
        border-bottom: solid 2px ${({ skin }) => Color.from(skin.colors.white).opaque.toString()};
        */
    }
    .custom-modal .custom-modal-close {
        float: right;
        display: block;
        width: 17px;
        height: 17px;
        cursor: pointer;
        background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.62/icons/no-w@2x.png');
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        margin-top: 6px;
    }

    .custom-modal .custom-modal-menu {
        float: right;
        display: none;
        width: 17px;
        height: 17px;
        cursor: pointer;
        background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.62/icons/burger-w@2x.png');
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        margin-top: 6px;
        margin-right: 8px;
    }

    .custom-modal.modal-warning {
        background-color: ${({ skin }) => Color.from(skin.colors.warning).opaque.toString()};
    }
    .custom-modal.modal-warning .custom-modal-header {
        background-color: ${({ skin }) => Color.from(skin.colors.warning).opaque.toString()};
    }
    .custom-modal.modal-warning .custom-modal-footer {
        text-align: center;
    }

    .custom-modal-adjanced-in {
        display: none;
    }
    .custom-modal-adjanced-out {
        display: block;
    }

    .modal-input-title {
        font-size: 0.9rem;
        color: ${({ skin }) => Color.from(skin.colors.dark).opaque.toString()};
        display: block;
    }
    .modal-input {
        width: 100%;
        margin-bottom: 20px;
        font-size: 1rem;
    }
    .custom-modal-footer {
        text-align: right;
    }
    .modal-button {
        margin: 5px 0px 5px 10px;
        padding-left: 18px;
        padding-right: 18px;
        font-size: 0.9rem;
    }

    .categorized-modal-content {
        display: flex;
        flex-direction: row;
        position: absolute;
        left: 10px;
        top: 60px;
        bottom: 0;
        right: 0;
        padding: 15px 20px;
        max-width: 100vw;
    }

    .categorized-modal-menu {
        width: 200px;
        min-width: 200px;
        margin-right: 20px;
        overflow-y: auto;
    }
    .categorized-modal-body {
        width: 100%;
        user-select: text;
    }

    @media /*[🍕]*/ only screen and (max-width: 575px) {
        .custom-modal:not(.menu-open) .categorized-modal-menu {
            display: none;
        }

        .custom-modal.menu-open .categorized-modal-body {
            display: none;
        }

        .custom-modal.menu-open .categorized-modal-menu {
            width: 100%;
            color: ${({ skin }) => Color.from(skin.colors.white).opaque.toString()};
        }

        .custom-modal.menu-open {
            background: ${({ skin }) => Color.from(skin.colors.primary).opaque.toString()};
        }

        .category-picker a li {
            color: ${({ skin }) => Color.from(skin.colors.white).opaque.toString()} !important;
        }

        .category-picker li.category-selected {
            color: ${({ skin }) => Color.from(skin.colors.white).opaque.toString()} !important;
            font-weight: bold;
        }

        .custom-modal .custom-modal-menu {
            display: block;
        }
    }

    @media /*[🍕]*/ only screen and (max-width: 850px) {
        .custom-modal {
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: unset;
            height: unset !important;
            max-width: unset;
            max-height: unset !important;
            transform: unset;
        }

        .custom-modal-adjanced-in {
            display: block;
        }

        .custom-modal-adjanced-out {
            display: none;
        }
    }
`;

/**
 * TODO: [🏴‍☠️] Across the projects components+files with styled name call WelcomeModalStyle (not WelcomeModalDiv)
 */
